var __jsx = React.createElement;
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from './Dropdown.style';
import ArrowDown from '../../../static/images/icons/ArrowDown';
import Button from '../../atoms/Button/Button';
import useMediaQuery from '../../../hooks/useMediaQuery';
import CustomIcon from '../../atoms/CustomIcon';
var getIcon = function getIcon(icon) {
  if (icon) {
    return __jsx(CustomIcon, {
      icon: icon
    });
  }
  return __jsx(ArrowDown, null);
};
var Dropdown = function Dropdown(_ref) {
  var children = _ref.children,
    className = _ref.className,
    classes = _ref.classes,
    title = _ref.title,
    label = _ref.label,
    arrowIcon = _ref.arrowIcon,
    icon = _ref.icon,
    Icon = _ref.Icon,
    type = _ref.type,
    openDropdownOnClick = _ref.openDropdownOnClick,
    buttonStyleType = _ref.buttonStyleType,
    analyticsType = _ref.analyticsType,
    analyticsValue = _ref.analyticsValue;
  var _useState = useState(false),
    isMenuOpen = _useState[0],
    setIsMenuOpen = _useState[1];
  var menuOpenTimeout = useRef(null);
  var wrapperRef = useRef(null);
  var isDesktop = useMediaQuery('lg');
  useEffect(function () {
    if (isMenuOpen) {
      document.addEventListener('click', handleOutsideClick, false);
    }
    return function () {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [isMenuOpen]);
  var handleOutsideClick = function handleOutsideClick(event) {
    if (!wrapperRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      document.removeEventListener('click', handleOutsideClick);
    }
  };
  var handleHover = function handleHover(shouldOpen) {
    clearTimeout(menuOpenTimeout.current);
    menuOpenTimeout.current = setTimeout(function () {
      setIsMenuOpen(shouldOpen);
    }, shouldOpen ? 0 : 100);
  };
  var handleClick = function handleClick(event) {
    if (openDropdownOnClick || !isDesktop) {
      return setIsMenuOpen(function (prevState) {
        return !prevState;
      });
    }

    // if not a mouse click
    if (event.nativeEvent.pageX === 0) {
      return setIsMenuOpen(function (prevState) {
        return !prevState;
      });
    }
  };
  return __jsx("div", {
    ref: wrapperRef,
    className: "".concat(className, " nva-dropdown ").concat(classes.wrapper),
    onMouseEnter: function onMouseEnter() {
      return !openDropdownOnClick && handleHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return !openDropdownOnClick && handleHover(false);
    }
  }, __jsx(Button, {
    className: "nva-dropdown__toggle nva-dropdown__toggle--".concat(type, " ").concat(classes.button, " "),
    styleType: buttonStyleType,
    title: title || label,
    onClick: handleClick,
    "aria-expanded": isMenuOpen ? 'true' : 'false',
    "data-analytics-type": analyticsType,
    "data-analytics-value": analyticsValue
  }, icon && __jsx(CustomIcon, {
    icon: icon
  }) || Icon && __jsx(Icon, null), label, __jsx("span", {
    className: "nva-dropdown__arrow"
  }, getIcon(arrowIcon))), __jsx("div", {
    className: "nva-dropdown__inner-wrapper ".concat(classes.dropdown)
  }, children));
};
Dropdown.prototype = {
  type: PropTypes.oneOf(['link', 'cta']),
  openDropdownOnClick: PropTypes.bool
};
export default styled(Dropdown).withConfig({
  componentId: "sc-1fe0v3m-0"
})(["", ";"], styles);